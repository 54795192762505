
@import "~@amf/shared/styles/variables";

$color-blue-10: #257AD4;
$color-text-primary: #4A4A4A;

:root {
  --color-blue-10: #{$color-blue-10};
  --color-text-primary: #{$color-text-primary};
}

@import "typography";

@import "components/layout";
@import "components/error";
@import "components/navigation";
@import "components/slider";
@import "components/content";
@import "components/footer";
@import "components/news";
@import "components/person";
@import "components/search";

@import "pages/notFoundPage";
@import "pages/newsDetailPage";
@import "pages/contactPage";

.PersonHeader {
  background: var(--color-white);
}
