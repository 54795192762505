
.Navigation {

  .Navigation--blue .Navigation__main {

  }

  &__brand {
    width: 56px;
    min-width: 56px;

    @media (max-width: $break-small) {
      width: 112px;
      min-width: 112px;
      height: 40px;
    }
  }

  &__menuItem--selected:after {
    background-image: url("~@amf/shared/assets/icon-cross-white.svg") !important;
  }

  &--white &__menuItem:before {
    background-image: url("~@amf/shared/assets/icon-menu-arrow-green-dark.svg") !important;
  }

  &--white &__submenuItem {
    color: var(--color-white);
  }

  &--white &__menuItem {
    color: var(--color-text-primary);
  }

  &--white &__menuItem--selected {
    color: var(--color-yellow);
  }
}
